import { InputText } from "primereact/inputtext";
import { Menubar } from "primereact/menubar";
import React, { FunctionComponent } from "react";
import { Link, useNavigate } from "react-router-dom";

/* interface HeaderMenuProps {
    
}
  */
const HeaderMenu: FunctionComponent = () => {
    const navigate = useNavigate();

    const items = [
        {
            label: 'Dashboard',
            icon: 'pi pi-home',
            command: () => {
                navigate("/")
            }
        },
        {
            label: 'Campaigns',
            icon: 'pi pi-megaphone',
            command: () => {
                navigate("/campaigns")
            }
        },
        {
            label: 'Brands',
            icon: 'pi pi-heart-fill',
            command: () => {
                navigate("/brands")
            }
        },
        {
            label: 'Blocks',
            icon: 'pi pi-th-large',
            command: () => {
                navigate("/blocks")
            }
        },
        // {
        //     label: 'Contact',
        //     icon: 'pi pi-envelope',
        //     badge: 3,
        //     template: itemRenderer
        // }
    ];
    
    
    const start = <Link to={"/"}><img alt="logo" src="/logo.png" height="40" className="mr-2"></img></Link>;
    const end = (
        <div className="flex align-items-center gap-2">
            {/* <InputText placeholder="Search" type="text" className="w-8rem sm:w-auto" /> */}
            {/*<Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png" shape="circle" /> */}
        </div>
    );

    return (<Menubar model={items} start={start} end={end}/>);
}
 
export default HeaderMenu;

// const itemRenderer = (item: any) => (
//     <a className="flex align-items-center p-menuitem-link">
//         <span className={item.icon} />
//         <span className="mx-2">{item.label}</span>
//         {item.badge && <Badge className="ml-auto" value={item.badge} />}
//         {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
//     </a>
// );
