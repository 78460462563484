/**
 * This function can be used anywhere in the app to greet the user
 * @param userName The user's first name
 * @returns A kind greeting message
 */
export const sayHello = (userName: string): string => {
    return 'Welcome ' + userName + '!'
}

export const sanitizeBuildLabel = (status: string | undefined): 'finish' | 'error' | 'wait' | 'process' | undefined => {
    switch (status) {
        case 'success':
            status = 'process'
            break
        case 'warn':
            status = 'error'
            break
        case 'error':
            status = 'error'
            break
        default:
            status = undefined
            break
    }
    return status as 'finish' | 'error' | 'wait' | 'process' | undefined
}