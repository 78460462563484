import React, { FunctionComponent, useEffect } from 'react'
import { BriefBuildDTO, RenderDTO } from '../../types/campaign'
import { ApiService } from '../../service/campaign'
import { Alert, Modal, Tabs } from 'antd'
import PreviewBuildRendersData from './CampaignBuildActions'

import { Tab } from 'rc-tabs/lib/interface'
import { EyeOutlined } from '@ant-design/icons'
import LanguageFlag from '../../layout/LanguageFlag'
import { Button } from 'primereact/button'

interface PreviewBuildRendersProps {
    build: BriefBuildDTO,
    //campaign: CampaignDTO,
    //lastUpdate: Date
}

interface PreviewBuildRendersData {
    loading: boolean;
    error: boolean; 
    renders: RenderDTO[]
}

const PreviewBuildRenders: FunctionComponent<PreviewBuildRendersProps> = (props: PreviewBuildRendersProps) => {
    const {build} = props
    
    const [renderData, setRenderData] = React.useState<PreviewBuildRendersData>({ loading: true, error: false, renders: [] })
    const [modalOpen, setModalOpen] = React.useState<boolean>(false)
    
    useEffect(() => {
        const API = new ApiService();
        API.getBuildRenders(build.id)
            .then((res) => {
                setRenderData({ loading: false, error: false, renders: res })
            })
            .catch((err) => {
                console.log(err)
                setRenderData({ loading: false, error: true, renders: [] })
            })
    }, [build.id, props.build])

    let items : Tab[] = []

    if (renderData) {
        items = Object.entries(renderData.renders).map(([key, renderData], index) => {
            const {status, message, html} = renderData;
            const hasError = status.includes("error");

            let renderChild =  <>
                <div style={{width: "100%"}}><iframe srcDoc={html} style={{width: "100%", height: "512px", border: 'solid 1px gray'}}></iframe></div>
            </>

            if(hasError){
                renderChild = <Alert
                message="Error in Rendering"
                description={message}
                type="error"
                showIcon
              />
            }

            return {
                label: <><span style={{fontSize: 10, textAlign: 'center'}}>{index}.</span><br/><LanguageFlag lang={renderData.lang} error={renderData.status.includes("error")}/></>,
                key: key,
                children: renderChild,
            }
        });
    }

    return (
        <>
        <Button onClick={() => setModalOpen(true)} size='small'><EyeOutlined />Preview</Button>
        {modalOpen && (
            <Modal open={modalOpen} onOk={() => setModalOpen(false)} onCancel={() => setModalOpen(false)} width={1080} title="Previewing">
                <Tabs items={items}/>
            </Modal>
        )}
        </>
    )

}

export default PreviewBuildRenders
