import React, { FunctionComponent, useEffect } from 'react'
import { CreateCampaignButton } from '../components/campaign/CreateCampaignButton';
import CampaignList from '../components/campaign/CampaignList';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import { Helmet } from 'react-helmet';
import { Input } from 'antd';
import { CampaignDTO } from '../types/campaign';
import { ApiService } from '../service/campaign';

const CampaignsPage: FunctionComponent = () => {

    const [data, setData] = React.useState<CampaignDTO[]>([])
    const [textFilter, setTextFilter] = React.useState("");

    const [_error, setError] = React.useState("");
    
    useEffect(() => {
        const API = new ApiService();
        API.getAllCampaigns()
            .then((res) => {

                //If user is searching
                if(textFilter && textFilter.trim() != ""){
                    const filteredRes = res.filter(f => f.brand.toLowerCase().includes(textFilter.toLowerCase()) || f.name.toLowerCase().includes(textFilter.toLowerCase()) || f.origin.toLowerCase().includes(textFilter.toLowerCase()))
                    setData(filteredRes)
                }else{
                    setData(res)
                }
        })
        .catch((err) => {
            setError(err.message)
        })
    }, [textFilter])

    const handleSearchChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if(ev.target.value){
            console.log(`Search input! ${ev.target.value}`)
            setTextFilter(ev.target.value)
        }else{
            setTextFilter("")
        }
    }

    return (
        <>
            <Helmet>
                <title>Campaigns List</title>
            </Helmet>
            <Title>
                All Campaigns
            </Title>
            <Paragraph>
            Campaignly allows to streamline the Marketing campaign creation experience by giving teams the possibility to have a reproducible and versioned build system for their day to day marketing operations.
            </Paragraph>
            <div style={{display: "flex"}}>
                <CreateCampaignButton onCampaignCreated={(campaign: CampaignDTO) => setData([campaign, ...data])}/>
                <div style={{flexGrow: 1}} />
                <Input placeholder={'Search a Campaign or Brand...'} onChange={handleSearchChange} style={{maxWidth: 512}}></Input>
            </div>
            <br/>
            <br/>
            <CampaignList data={data}/>
        </>
    )
}

export default CampaignsPage
