import { ApiService } from "../service/campaign"

/**
 * 
 * @param sourceBuild 
 * @param campaignName 
 */
const downloadRenderArchive = (buildId: string, campaignName: string) => {
    const API = new ApiService();

    const finalFileName = `${campaignName.trim()}-${buildId.split("-").pop()}.zip`

    API.downloadZipByBuildId(buildId)
    .then((blob: { data: BlobPart }) => {
        const url = window.URL.createObjectURL(
            new Blob([blob.data] /* , { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' } */)
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', finalFileName)
        document.body.appendChild(link)
        link.click()
    })
    .catch((e: any) => {
        alert(e)
    })
}

export {downloadRenderArchive}