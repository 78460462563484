import React, { FunctionComponent, useEffect } from 'react'
import { ApiService } from '../service/campaign';
import BlockGallery, { BlockDTO } from '../components/blocks/BlockGalleryComponent';
import Title from 'antd/es/typography/Title';
import BrandChooserComponent from '../components/blocks/BrandChooserComponent';
import {Helmet} from "react-helmet";

const BlockGalleryPage: FunctionComponent = () => {
    const [brand, setBrand] = React.useState("");

    const [data, setData] = React.useState<BlockDTO[]>([])
    const [_error, setError] = React.useState("");
    
    useEffect(() => {
        const API = new ApiService();
        if(!brand){
             setError("Missing brand value!")
        }else{
            API.getBlocks(brand)
              .then((res) => {
                setData(res)
            })
            .catch((err) => {
                setError(err.message)
            })
        }
    }, [brand])

    const onBrandChange = (brand: string) => {
        console.log("Brand Changed. Calling API.");
        setBrand(brand)
    }
    
    return (
      <>
      <Helmet>
        <title>Block Library {brand ? ` - ${brand}`:""}</title>
      </Helmet>
      <Title>Block Gallery</Title>
      <BrandChooserComponent onBrandChanged={onBrandChange}/>
      <br/>
      {brand != "" && <BlockGallery blocks={data} brand={brand}/>}
      </>
    );
}
 
export default BlockGalleryPage;