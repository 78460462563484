import React, { FunctionComponent, useEffect } from 'react'
import { BriefBuildDTO, CampaignDTO } from '../../types/campaign'
import { ApiService } from '../../service/campaign'
import { Flex, List, Skeleton, Steps } from 'antd'
import { Link } from 'react-router-dom'
import ConvertLanguageData from '../service/ConvertLanguageData'
import { sanitizeBuildLabel } from '../../utility/functions'
import BuildActions from './CampaignBuildActions'

interface CampaignBuildsProps {
    campaign: CampaignDTO,
    lastUpdate: Date
}

interface CampaignBuildData {
    loading: boolean;
    error: boolean;
    builds: BriefBuildDTO[]
}

const CampaignBuilds: FunctionComponent<CampaignBuildsProps> = (props: CampaignBuildsProps) => {
    const API = new ApiService();
    const [buildData, setBuildData] = React.useState<CampaignBuildData>({ loading: true, error: false, builds: [] })

    const { builds } = buildData

    useEffect(() => {
        API.getBuildsByCampaignId(props.campaign.id)
            .then((buildsData) => {
                if(buildsData){
                    console.log(buildsData)

                    const last5 = buildsData.slice(0, 5)
                
                    setBuildData({ loading: false, error: false, builds: last5 })
                }else{
                    console.log(`No build data.`)
                    setBuildData({ loading: false, error: true, builds: [] })
                }
            })
            .catch((error) => {
                console.error('error!', error)
                setBuildData({ loading: false, error: true, builds: [] })
            })
    }, [props.campaign, props.lastUpdate])

    const upsertItems = (newBuild: BriefBuildDTO) => {
        //If id is equal, return new obj, otherwise keep the old one
        let newBuildsArr = builds.map((originalBuild) => {
            if(originalBuild.id == newBuild.id){
                return newBuild
            }else{
                return originalBuild
            }
        })

        if(!newBuildsArr.includes(newBuild)){
            newBuildsArr = [newBuild, ...newBuildsArr];
        }

        setBuildData({...buildData, builds: newBuildsArr})
    }

    const deleteItem = (build: BriefBuildDTO) => {
        //Filter by eliminating old Item Id
        const newBuildsArr = builds.filter(b => b.id != build.id)
        setBuildData({...buildData, builds: newBuildsArr})
    }

    return (
        <Flex gap="middle" vertical>
            {buildData.loading && (
                <Skeleton/>
            )}
            {!buildData.loading && buildData.builds && (
                <div>
                    <List
                        itemLayout="vertical"
                        dataSource={buildData.builds}
                        renderItem={(buildItem, index) => <BuildItem build={buildItem} campaign={props.campaign} onBuildUpdate={upsertItems} onBuildDelete={deleteItem} key={index}/>}/>
                </div>
            )}
        </Flex>
    )
}


interface BuildItemProps {
    build: BriefBuildDTO,
    campaign: CampaignDTO
    onBuildUpdate: (build: BriefBuildDTO) => void
    onBuildDelete: (build: BriefBuildDTO) => void
}

const BuildItem : FunctionComponent<BuildItemProps> = ({build, campaign, onBuildUpdate, onBuildDelete}: BuildItemProps) => {

    const convertStepPredicate = () => {
        const convertStatus = ["convert", "clone"]
        return convertStatus.includes(build.build_status[0].name.toLowerCase());
    }

    const stepItems = build.build_status.map((step) => {return {title: step.name, status: sanitizeBuildLabel(step.status)}});
    const isBuilt = !(build.build_status.length == 1 && (convertStepPredicate));

    return (
        <List.Item>
            <List.Item.Meta
                // avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                title={<Link to={`./renders/${build.id}`}>{build.id.substring(build.id.lastIndexOf("-")+1, build.id.length)}</Link>}
                description={<>
                        <ConvertLanguageData build={build} />
                        <Steps type="inline" /* status={'finish'} */ items={stepItems} />
                        </>
                }
            />
            <BuildActions buildData={build} parentCampaign={campaign} onUpdate={onBuildUpdate} onClone={onBuildUpdate} isBuilt={isBuilt} onDelete={onBuildDelete}/>
        </List.Item>
    )

}

export default CampaignBuilds
