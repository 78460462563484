import React, { FunctionComponent, useEffect } from 'react'
import { ApiService } from '../../service/campaign'
import { BriefDTO, CampaignDTO } from '../../types/campaign'
import { Flex, Skeleton, Tooltip } from 'antd'
import Table, { ColumnsType } from 'antd/es/table'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs' // ES 2015

import relativeTime from 'dayjs/plugin/relativeTime'
import CampaignMetadata from './CampaignMetadata'
import { ClockCircleOutlined, FileExcelOutlined } from '@ant-design/icons'
import { BrandColumn, TicketColumn } from './table/CampaignTableColumns'
dayjs.extend(relativeTime)

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CampaignListProps {
    data: CampaignDTO[]
}

interface CampaignListData {
    loading: boolean
    error: boolean
    data: CampaignDTO[]
}

const columns: ColumnsType<CampaignDTO> = [
    Table.EXPAND_COLUMN,
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, campaign) => {
            const {id, name} = campaign;
            //const color = brand == 'DIESEL' ? 'volcano':'blue';
            return (
                <Flex vertical>
                    <Link to={`/campaigns/${id}`}>{name}</Link>
                    <div><BrandColumn {...campaign}/></div>
                </Flex>
            )
        }
    },
    {
        title: 'Brief Count',
        dataIndex: 'briefs',
        key: 'briefs',
        sorter: (briefA, briefB) => {
            const getLastBriefDate = ({briefs}:CampaignDTO) => {
                if(briefs){
                    return briefs.map((d) => dayjs(d.created_at).unix())[briefs.length - 1]
                }else{
                    return 0;
                }
            }

            return getLastBriefDate(briefA) - getLastBriefDate(briefB)
        },
        render: (briefList: BriefDTO[]) => {
            if (briefList) {
                return (
                    <>
                        <Tooltip title={<><ul>{briefList.map(b => <li key={b.id}>{b.created_at.toString()}</li>)}</ul></>}>
                            <FileExcelOutlined /> <a>{briefList.length}</a>
                        </Tooltip><br/>
                        <ClockCircleOutlined title='Last Brief Upload'/> {briefList.map((d) => dayjs(d.created_at).fromNow())[briefList.length - 1]}
                    </>
                )
            }
            return <></>
        }
    },
    {
        title: 'Category',
        key: 'category',
        dataIndex: 'category'
    },
    {
        title: 'Issue',
        key: 'origin',
        dataIndex: 'origin',
        render: (_: any, campaign: CampaignDTO) => <TicketColumn campaign={campaign} id={campaign.id} issue={campaign.origin}/>,
        filters: [
            
        ]
    }
]

const CampaignList: FunctionComponent<CampaignListProps> = (props: CampaignListProps) => {
    const [data, setData] = React.useState<CampaignListData>({ loading: true, error: false, data: [] })
    //const [open, setOpen] = React.useState(false)
    //const [preview, setPreview] = React.useState('')

    useEffect(() => {
        const API = new ApiService()
        API.getAllCampaigns()
            .then((res) => {
                //res.push(...res, ...res, ...res, ...res)
                setData({ loading: false, error: false, data: res })
            })
            .catch((err) => {
                console.log(err)
                setData({ loading: false, error: true, data: [] })
            })
    }, [])

    if (data.loading) {
        return (
            <>
                <Skeleton loading={data.loading} />
            </>
        )
    }

    return (
        <>
            <Table 
                columns={columns} 
                dataSource={props.data} 
                rowKey={(record: CampaignDTO) => record.id}
                expandable={{
                    expandedRowRender: (record) => <CampaignMetadata campaign={record} />,
                }}
                scroll={{ y:  720}}
            />
        </>
    )
}

export default CampaignList
