import React, { FunctionComponent, useEffect, useMemo } from 'react'
import { Card, Descriptions } from 'antd'
import { DescriptionsItemType } from 'antd/es/descriptions'
import { useParams } from 'react-router-dom'
import { ApiService } from '../service/campaign'
import { BriefBuildDTO } from '../types/campaign'
import Title from 'antd/es/typography/Title'
import { BuildStepFeedback } from '../components/brief/BuildStepFeedback'
import { LikeOutlined } from '@ant-design/icons';
import { Col, Row, Statistic } from 'antd';
import BriefRenders from '../components/renders/BriefRenders'

interface BriefBuildDetail {
    loading: boolean
    error: boolean
    data: BriefBuildDTO[]
}

const BriefDetailPage: FunctionComponent = () => {
    const { id, briefid } = useParams()
    const [buildData, setBuildData] = React.useState<BriefBuildDetail>({ loading: true, error: false, data: [] })
    const [lastBuild, setLastBuildData] = React.useState<BriefBuildDTO>()

    useMemo(() => {
        if (buildData.data) {
            //console.log(`Setting last build to ${JSON.stringify(buildData.data[0])}`)
            setLastBuildData(buildData.data[0])
        }
    }, [buildData])
    // const updateCampaign = (id: string) => {
    //     const API = new ApiService()
    //     API.getCampaignById(id)
    //       .then((res) => {
    //             setCampaignData({...campaignData, loading: false, data: res})
    //       })
    //       .catch((err) => {
    //             setCampaignData({...campaignData, loading: false, error: err, data: null})
    //       })
    // }

    useEffect(() => {
        const API = new ApiService()
        if (id && briefid) {
            API.getBriefBuilds(id?.toString(), briefid)
                .then((success) => {
                    //console.log(success)
                    setBuildData({ loading: false, error: false, data: success })
                })
                .catch((error) => {
                    console.error('error!', error)
                    setBuildData({ loading: false, error: true, data: [] })
                })
        } else {
            console.warn("no params set.")
        }
    }, [briefid, id])

    //const lastBuild = buildData.data ? buildData.data[0] : null;

    const items: DescriptionsItemType[] = [
        {
            label: 'Brief Id',
            span: 1,
            children: <b>{briefid}</b>
        },
        {
            label: 'Head',
            span: 1,
            children: <b>{JSON.stringify(lastBuild?.commonData)}</b>
        },
        {
            label: 'UTM',
            span: 1,
            children: <>{lastBuild && <>{JSON.stringify(lastBuild.commonData.utm)}</>}</>
        }
    ]

    const getLanguages = () => {
        const languages = Object.keys(buildData.data);
        return languages
    }

    return (
        <>
            <div key='c' style={{ width: '100%' }}>
                <Descriptions title="Brief Details" bordered column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }} items={items} />
                {buildData.data && 
                    <>
                    <Title level={3}>Statistics</Title>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Card bordered={false}>
                                <Statistic title="Languages" value={getLanguages().length} prefix={<LikeOutlined />} />
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card bordered={false}>
                                <Statistic title="Unmerged" value={93} suffix="/ 100" />
                            </Card>
                        </Col>
                    </Row>
                    <Title level={3}>Build History</Title>
                    </>
                }
                {/** BuildData of campaign */}
                {buildData.data && buildData.data.map((val, k) => 
                <>
                    <div key={k}>
                        <small>{val.id}</small><br/>
                        <small>{val.created_at.toString()}</small><br/><br/>
                        <BuildStepFeedback build={val.build_status} direction='horizontal'/>
                        {id && <BriefRenders campaignId={id} briefId={val.brief_id} buildId={val.id}/>}
                    </div>
                    <hr/>
                </>
                )}
                {!buildData.data &&  
                <>
                    <hr/>
                    <div>
                        Nothing to show here!
                    </div>
                </>
                }
            </div>
        </>
    )
}

export default BriefDetailPage
