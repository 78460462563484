import React, { FunctionComponent, useMemo, useState } from "react";
import { CodeOutlined } from "@ant-design/icons";
import { Card, Statistic } from "antd";
import axios from "axios";

/* interface ConverterHealthProps {
    
} */
 
const CampaignStats: FunctionComponent = () => {

    const [builtNumber,setBuiltNumber] = useState(0);
    const [loading,setLoading] = useState(true);

    const apiInstance = axios.create({
        baseURL: '/api/v1',
        //timeout: 10000,
        headers: { 'X-Frontend': 'campaignly' },
    });

    apiInstance.interceptors.response.use((response) => {
        if (response.status == 204) {
            console.debug(`Intercepted no content [${response.status}]`)
            //return Promise.resolve([])
        }
        return response;
    });

    useMemo(()=>{
        apiInstance.get(`/stats/campaigns`)
        .then(value => {
            console.debug("Got Campaigns Successfully!");
            console.debug(value.data);
            setBuiltNumber(value.data.count)
        })
        .catch(error => {
            console.debug("API Error: ", error);
            setBuiltNumber(0)
        })
        .finally(() => {
            setLoading(false)
        });
    },[apiInstance])

    return (
    <>
        <Card bordered={true}>
            <Statistic title="Total Campaigns" value={builtNumber} prefix={<CodeOutlined />}/>
        </Card>
    </>
    );
}
 
export default CampaignStats;