import React, { Fragment, useEffect } from 'react'

import { Card, Flex, Modal, Spin } from 'antd'
import Meta from 'antd/es/card/Meta'
import { FunctionComponent } from 'react'
import { CodeOutlined } from '@ant-design/icons'
import { ApiService } from '../../service/campaign'
import { useToggle } from '@uidotdev/usehooks'
import { MONACO_EDITOR_OPTIONS } from '../../utility/editorConfig'
import { Editor } from '@monaco-editor/react'

export interface BlockDTO {
    name: string
    lmd: string
}

interface BlockGalleryProps {
    brand: string
    blocks: BlockDTO[]
}

interface BlockProps {
    brand: string
    block: string
}

const BlockRender: FunctionComponent<BlockProps> = (props: BlockProps) => {
    const { brand, block } = props

    const [blockData, setBlockData] = React.useState<{error: string, loading: boolean, data: string}>({error: '', loading: true, data: ''})

    useEffect(() => {
        const API = new ApiService()
        API.getBlockPreview(brand, block)
            .then((res) => {
                console.log(`BlockData for ${block}`)
                setBlockData({error: '', data: res, loading: false})
            })
            .catch((err) => {
                setBlockData({...blockData, error: err, loading: false})
            })
    }, [brand, block])

    if(!blockData.error && !blockData.loading){
      return (
        <div style={{ height: 256, overflowY: 'scroll' }} dangerouslySetInnerHTML={{__html: blockData.data}}></div>
      )
    }else{
      return (<div style={{ height: 256, overflowY: 'scroll' }}><Spin /></div>)
    }

}

const BlockDisplay: FunctionComponent<{brand: string, block: string, lmd: string}> = ({brand, block: blockName, lmd}) => {

    const [showCode, toggleShowCode] = useToggle(false)
    const [blockData, setBlockData] = React.useState<{error: string, loading: boolean, data: string}>({error: '', loading: true, data: ''})

    useEffect(() => {
        const API = new ApiService()
        API.getBlockPreview(brand, blockName)
            .then((res) => {
                console.log(`BlockData for ${blockName}`)
                setBlockData({error: '', data: res, loading: false})
            })
            .catch((err) => {
                setBlockData({...blockData, error: err, loading: false})
            })
    }, [brand, blockName])

    return (
        <>
        {showCode && (
            <Fragment>
                <Modal open={showCode} onOk={() => toggleShowCode()} onCancel={() => toggleShowCode(false)} width={1024}>
                    <Editor
                        language="html"
                        theme="vs-dark"
                        options={MONACO_EDITOR_OPTIONS}
                        value={blockData.data}
                        //onChange={onEditorValueChange}
                        height={512}
                    /> 
                </Modal>
            </Fragment>
        )}
        <Card
            style={{ width: 512 }}
            cover={
                <BlockRender block={blockName} brand={brand}/>
            }
            actions={[
                <CodeOutlined key="view" onClick={_t => toggleShowCode()} label={showCode ? "red":"gray"} title='Show block code'/>
            ]}
        >
            <Meta title={blockName} description={lmd} />
        </Card>
        </>
    )
}

const BlockGallery: FunctionComponent<BlockGalleryProps> = (props: BlockGalleryProps) => {
    const { brand } = props

    if (props.blocks.length > 0) {
        return (
            <Flex wrap="wrap" gap="small" justify="center">
                {props.blocks.map(({ name, lmd }, idx) => <BlockDisplay key={idx} brand={brand} block={name} lmd={lmd} />)}
            </Flex>
        )
    } else {
        return <>Nothing to show!</>
    }
}

export default BlockGallery
