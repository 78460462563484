import { HeatMapOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useToggle } from "@uidotdev/usehooks";
import { App, Flex, Button, Input } from "antd";
import React, { FunctionComponent, useState } from "react";
import { ApiService } from "../../service/campaign";
import { CampaignDTO } from "../../types/campaign";

const TicketEditor: FunctionComponent<CampaignDTO> = (props:CampaignDTO) => {
    const {id, origin} = props;

    const {message} = App.useApp();

    const [editMode, toggleEditMode] = useToggle(false);
    const [ticketId, setTicketId] = useState(origin);

    const handleSubmit = () => {
        if(ticketId == origin){
            console.log("Same TicketId. No need to update.");
            toggleEditMode()
            return;
        }

        const API = new ApiService()
        //Push updated Campaign Data to API
        API
            .editCampaignById(id, {...props, origin: ticketId})
            .then((success) => {
                message.success(`Ticket successfully updated! ${success.origin}`);
                toggleEditMode();
            })
            .catch((_error) => {
                message.error(`Error while saving ticket data!`);
            })
    }

    const handleCancel = () => {
        //Push updated Campaign Data to API
        setTicketId(origin);
        toggleEditMode()
    }

    const handleKeyDown = (event: { key: string; }) => {
        if (event.key === 'Enter') {
          console.log('do validate')
          handleSubmit();
        }
        if(event.key === 'Escape'){
            handleCancel();
        }
    }

    if(ticketId && !editMode){
        return(
            <>
                <i className="pi pi-pen-to-square" title="Edit linked ticket" style={{cursor: 'pointer'}} onClick={() => {toggleEditMode()}}></i>
                &nbsp;
                <i className="pi pi-external-link" title="Open linked ticket" style={{cursor: 'pointer'}} onClick={() => window.open(`https://onlythebrave.atlassian.net/browse/${ticketId}`)}></i>
                &nbsp;
                {ticketId}
            </>
        );
    }else{
        return (
            <Flex gap={2}>
                {!editMode && <>
                    <Button icon={<HeatMapOutlined />} onClick={() => toggleEditMode()}>
                        Link Issue
                    </Button>
                </>}
                {editMode && <>
                    <Input type={"text"} value={ticketId} onChange={(val) => setTicketId(val.target.value.trim())} onKeyDown={handleKeyDown} placeholder="DEMC-000" allowClear autoFocus/>
                    <Button icon={<CheckOutlined />} onClick={handleSubmit}></Button>
                    <Button icon={<CloseOutlined />} onClick={handleCancel}></Button>
                </>}
            </Flex>
        );
    }
}

export default TicketEditor;