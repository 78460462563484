import React, { FunctionComponent } from 'react'
import { CampaignDTO } from '../types/campaign'
import { Alert, Card, Statistic, Typography } from 'antd';

const { Title, Paragraph } = Typography;
import { ApiOutlined } from '@ant-design/icons';
import ConverterHealth from '../components/integration/ConverterHealth';
import CampaignStats from '../components/integration/CampaignStats';
import BuildStats from '../components/integration/BuildStats';
import BuilderHealth from '../components/integration/BuilderHealth';
import Container, { FlexContainer } from '../layout/Container';
 
const HomePage: FunctionComponent = () => {
    return (
        <>
        <h1>Home</h1>
        <Alert message="Welcome" description="This is version 1.5! Some enhancements have been made for a better experience." type={"info"} showIcon closable/>
        <FlexContainer /* columns={2} */>
            <Container>
                <Title level={3}>Application Status</Title>
                    <div style={{display: 'flex', gap: 10, flexWrap: 'wrap'}}>
                        <Card bordered={true}>
                            <Statistic title="Connectors" value={0} prefix={<ApiOutlined />} />
                        </Card>
                        <CampaignStats />
                        <BuildStats />
                        <ConverterHealth />
                        <BuilderHealth />
                    </div>
            </Container>
            <Container>
                <Paragraph>
                    <Title level={3}>Changelog</Title>
                    <ul>
                        <li>Added campaign search feature</li>
                        <li>Block library does not buffer overflow anymore</li>
                        <li>Block library lets you see block code (work in progress)</li>
                    </ul>
                    Some things have moved. Have a look around.
                </Paragraph>
            </Container>
        </FlexContainer>
        </>
    );
}
 
export default HomePage;