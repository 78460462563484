import React, { FunctionComponent, ReactNode } from 'react'
import { theme } from 'antd'

import styles from './container.module.scss'

type Props = {
    children: ReactNode,
    variant?: "horizontal"
}

const Container: FunctionComponent<Props> = ({ children, variant }: Props) => {
    const {
        token: { colorBgContainer, borderRadiusLG }
    } = theme.useToken()

    const styleClasses = [
        styles.Container
    ]

    if (variant == "horizontal"){
        styleClasses.push(styles.Horizontal)
    }

    return (
        <div
            className={styleClasses.join(" ")}
            style={{
                background: colorBgContainer,
                borderRadius: borderRadiusLG
            }}
        >
            {children}
        </div>
    )
}

type FlexContainerProps = {
    children: ReactNode,
    variant?: "horizontal"
}

const FlexContainer: FunctionComponent<FlexContainerProps> = ({ children, variant }: FlexContainerProps) => {

    const styleClasses = [
        styles.FlexContainer
    ]

    if (variant == "horizontal"){
        styleClasses.push(styles.Horizontal)
    }

    return (
        <div className={styleClasses.join(" ")}>
            {children}
        </div>
    )
}

export {FlexContainer}
export default Container
