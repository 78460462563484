import { Flex } from "antd";
import React, { FunctionComponent } from "react";
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { hasFlag } from "country-flag-icons";

interface LanguageFlagProps {
    lang: string,
    locale?: string,
    edited?: boolean
    error?: boolean
}
 
const LanguageFlag: FunctionComponent<LanguageFlagProps> = (props: LanguageFlagProps) => {
    const { lang, error } = props;
        const upperLang = lang.toUpperCase()
        //const cc = ld.substring(0, 2).toUpperCase()
        //console.log(`Flag for lang ${lang}`)
        let flag = ""

        if (hasFlag(upperLang)) {
            flag = getUnicodeFlagIcon(upperLang)
        }
        if (lang.toLowerCase().startsWith("en")) {
            flag = getUnicodeFlagIcon('GB')

            if(props.locale && props.locale.toLowerCase().endsWith("us")){
                flag = getUnicodeFlagIcon('us')
            }

            if(props.locale && props.locale.toLowerCase().endsWith("hk")){
                flag = getUnicodeFlagIcon('hk')
            }
        }

        if (error) {
            flag = "⚠️"
        }

        return (
            <Flex key={lang} vertical align={'center'} title={props.locale}>
                    <span>{flag ? flag:<>&#x1F6A9;</>}</span>
                    <span style={{fontSize: 10, color: '#FFF'}}>{lang.charAt(0).toUpperCase() + lang.substring(1, lang.length).toUpperCase()} {props.edited && "*"}</span>
            </Flex>
        )
}
 
export default LanguageFlag;