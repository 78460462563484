import { Steps } from "antd";
import React, { FunctionComponent } from "react";
import { DeliveryRenderStep } from "../../types/campaign";
import { sanitizeBuildLabel } from "../../utility/functions";

interface BuildStepFeedbackProps {
    build: DeliveryRenderStep[]
    direction: "vertical" | "horizontal" | undefined
}
 
export const BuildStepFeedback: FunctionComponent<BuildStepFeedbackProps> = (props: BuildStepFeedbackProps) => {
    const items = props.build.map(b => {
        const status = sanitizeBuildLabel(b.status);

        return {
            title: b.name, 
            status: status, 
            description: ""
        };
    });

    return (
        <Steps type='default' direction={props.direction} size='small' items={items}/>
    )
}