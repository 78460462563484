import { AlertOutlined, BlockOutlined } from "@ant-design/icons";
import { Card, Statistic } from "antd";
import axios from "axios";
import React, { FunctionComponent, useCallback, useMemo, useState } from "react";

/* interface ConverterHealthProps {
    
} */
 
const BuilderHealth: FunctionComponent = () => {

    const [healthy,setHealthy] = useState(false);
    const [loading,setLoading] = useState(true);

    const apiInstance = axios.create({
        baseURL: '/api/v1',
        //timeout: 10000,
        headers: { 'X-Frontend': 'campaignly' },
    });

    apiInstance.interceptors.response.use((response) => {
        if (response.status == 204) {
            console.debug(`Intercepted no content [${response.status}]`)
            //return Promise.resolve([])
        }
        return response;
    });

    const pollApi = useCallback(() => {
        apiInstance.get(`/integration/builder/health`)
            .then(value => {
                console.debug("Got builder health Successfully!");
                console.debug(value.data);
                setHealthy(value.data.code == 200)
            })
            .catch(error => {
                console.debug("API Error: ", error);
                setHealthy(false)
            })
            .finally(() => {
                setLoading(false)
            });
    }, [apiInstance])

    useMemo(()=>{
        pollApi();
    },[pollApi])

    const valColor = healthy ? { color: '#3f8600' }:{color: 'red'};
    const value = healthy ? "Up":"Down";

    return (
    <>
        <Card bordered={true}>
            <Statistic title="Build Service" value={value} loading={loading} prefix={healthy ? <BlockOutlined />:<AlertOutlined/>} valueStyle={valColor}/>
        </Card>
    </>
    );
}
 
export default BuilderHealth;