import React, { FunctionComponent, useEffect, useState } from 'react'
import { DeploymentUnitOutlined, EditOutlined, GlobalOutlined, KeyOutlined, LoginOutlined } from '@ant-design/icons'
import { Modal, Input, Form } from 'antd'
import BrandChooserComponent from '../blocks/BrandChooserComponent'
import Title from 'antd/es/typography/Title'
import { ApiService } from '../../service/campaign'
import { CampaignDTO } from '../../types/campaign'
import { Button } from 'primereact/button'

export interface CampaignCreationModalProps {
    //isModalOpen: boolean
    onCampaignCreated: (camp: CampaignDTO) => void
}

const CreateCampaignButton: FunctionComponent<CampaignCreationModalProps> = (props: CampaignCreationModalProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [campaignData, setCampaignData] = useState<CampaignMetadata>()

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        const API = new ApiService()
        if (campaignData) {
            API.createNewCampaign(campaignData)
                .then((success) => {
                    props.onCampaignCreated(success)
                    setIsModalOpen(false)
                })
                .catch((err) => {
                    alert('Error! ' + err)
                })
        }
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <>
            <Button onClick={showModal}>
                Create New
            </Button>

            <Modal
                open={isModalOpen}
                okButtonProps={{ disabled: campaignData && campaignData.brand && campaignData.ftp_path && campaignData.name ? false : true }}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Title level={3}>Create Marketing Campaign</Title>
                {/*<Lorem count={2} />*/}
                <CampaignMetadataForm onValueChange={(ccf) => setCampaignData(ccf)} mode={'create'}/>
            </Modal>
        </>
    )
}

export interface CampaignEditProps {
    //isModalOpen: boolean
    campaign: CampaignDTO,
    onCampaignEdited: (camp: CampaignDTO) => void
}

const EditCampaignButton: FunctionComponent<CampaignEditProps> = (props: CampaignEditProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [campaignData, setCampaignData] = useState<CampaignMetadata>(props.campaign)

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        const API = new ApiService()
        if (campaignData) {
            API.editCampaignById(props.campaign.id, campaignData)
                .then((success) => {
                    console.log(success)
                    props.onCampaignEdited(success)
                    setIsModalOpen(false)
                })
                .catch((err) => {
                    alert('Error! ' + err)
                })
        }
    }

    const handleCancel = () => {
        setIsModalOpen(false)
        setCampaignData(props.campaign)
    }

    return (
        <>
            <Button icon={<EditOutlined />} onClick={showModal}>Edit Campaign Data</Button>

            <Modal
                open={isModalOpen}
                okButtonProps={{ disabled: campaignData && campaignData.brand && campaignData.ftp_path && campaignData.name ? false : true }}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Title level={3}>Edit Campaign</Title>
                {/*<Lorem count={2} />*/}
                <CampaignMetadataForm data={{...props.campaign}} onValueChange={(ccf) => setCampaignData(ccf)} mode={'edit'}/>
            </Modal>
        </>
    )
}

export interface CampaignMetadata {
    name: string
    ftp_path: string
    brand: string
    origin: string
    operation_id: string
    category: string
}

interface CampaignMetadataFormProps {
    data?: CampaignMetadata
    mode: "create"|"edit",
    onValueChange: (ccf: CampaignMetadata) => void
}

const CampaignMetadataForm: FunctionComponent<CampaignMetadataFormProps> = (props: CampaignMetadataFormProps) => {
    const [form] = Form.useForm()

    const initialData = {
        name: '',
        ftp_path: '',
        brand: '',
        origin: '',
        operation_id: '',
        category: '',
        ...props.data,
    }

    const [formData, setFormData] = useState<CampaignMetadata>(initialData)
    const { onValueChange } = props

    useEffect(() => {
        onValueChange(formData)
    }, [formData, onValueChange])

    return (
        <Form layout={'vertical'} form={form} style={{ maxWidth: 600 }}>
            {/**
             * Brand could be changed only if the modal is in Create mode. In edit mode, brand should not change.
             */}
            {props.mode == "create" && 
                <Form.Item label="Brand" name="brand" required>
                    <BrandChooserComponent onBrandChanged={(brand) => setFormData({ ...formData, brand: brand })} />
                </Form.Item>
            }
            <Form.Item label="Campaign Name *">
                <Input
                    prefix={<DeploymentUnitOutlined className="site-form-item-icon" />}
                    placeholder="2024-01-01_SS24_NewMarketingCampaign"
                    name="name"
                    value={formData.name}
                    onChange={(ev) => setFormData({ ...formData, name: ev.target.value })}
                    status={formData.name ? '' : 'error'}
                />
            </Form.Item>
            <Form.Item label="FTP Path">
                <Input
                    prefix={<GlobalOutlined className="site-form-item-icon" />}
                    placeholder="SS24_NewMarketingCampaign"
                    name="ftp_path"
                    value={formData.ftp_path}
                    onChange={(ev) => setFormData({ ...formData, ftp_path: ev.target.value })}
                    status={formData.ftp_path ? '' : 'error'}
                />
            </Form.Item>
            <Form.Item label="Jira Ticket">
                <Input
                    prefix={<KeyOutlined className="site-form-item-icon" />}
                    placeholder="DEMC-123"
                    name="issue_key"
                    value={formData.origin}
                    onChange={(ev) => setFormData({ ...formData, origin: ev.target.value })}
                    //status={formData ? '' : 'error'}
                />
            </Form.Item>
            <Form.Item label="Adobe Campaign OP">
                <Input
                    prefix={<i className="pi pi-id-card"></i>}
                    placeholder="OP1111"
                    name="issue_key"
                    value={formData.operation_id}
                    onChange={(ev) => setFormData({ ...formData, operation_id: ev.target.value })}
                    //status={formData ? '' : 'error'}
                />
            </Form.Item>
            <Form.Item label="Category">
                <Input
                    prefix={<i className="pi pi-table"></i>}
                    placeholder="STORE OP/CL/RF"
                    name="category"
                    value={formData.category}
                    onChange={(ev) => setFormData({ ...formData, category: ev.target.value })}
                />
            </Form.Item>
        </Form>
    )
}

export { CreateCampaignButton, EditCampaignButton }
