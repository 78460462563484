import React, { FunctionComponent, useEffect } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Modal, Tabs } from "antd";
import { ApiService } from "../../service/campaign";
import { BriefBuildDTO } from "../../types/campaign";
import { LanguageDataPayload } from "../../types/payloads";
import { Tab } from 'rc-tabs/lib/interface'
import LanguageCopyEditor, { LanguageCopyEditMap } from "./LanguageCopyEditor";
import LanguageFlag from "../../layout/LanguageFlag";

interface LanguageCopiesEditorProps {
    sourceBuild: BriefBuildDTO
}

interface LanguageCopiesEditorState {
    loading: boolean
    error: boolean
    data: LanguageCopyEditMap[]
}

const LanguageCopiesEditor: FunctionComponent<LanguageCopiesEditorProps> = (props: LanguageCopiesEditorProps) => {

    const [isOpen, setOpen] = React.useState(false)

    const [languageVariants, setLanguageVariants] = React.useState<LanguageCopiesEditorState>({ loading: true, error: false, data: [] })

    useEffect(() => {
        const API = new ApiService()
        API.getLanguageDataByBuildId(props.sourceBuild.id)
            .then((success) => {
                console.debug(success)
                const ldMap = success.map((element) => { return {data: element, modified: false}})
                
                setLanguageVariants({ loading: false, error: false, data: ldMap })
            })
            .catch((error) => {
                console.error('error!', error)
                setLanguageVariants({ loading: false, error: true, data: [] })
            })
    }, [props.sourceBuild])

    const onLangDataSave = (newLangData: LanguageDataPayload) => {
        //let newLd = langData;

        const newLd = languageVariants.data.map((entry) => {
            console.debug(entry)
            //return entry.data.id == newLangData.id ? newLangData:langData
    
            if (entry.data.id == newLangData.id){
                //const modified = isEqual(entry, newLangData)
                console.debug(`Saved Language [${newLangData.lang}] - Setting Modified: false`)
                return {data: newLangData, modified: false}
            }else{
                return entry
            }
        });

        setLanguageVariants({...languageVariants, data: newLd})
    }

    const onLangDataEdit = (newLangData: LanguageDataPayload, hasEdits: boolean) => {

        const updatedLdMap = languageVariants.data.map((entry) => {
            const { data } = entry

            if (data.id == newLangData.id){
                console.debug(`Edited Language [${data.lang}/${newLangData.lang}] - modified ${hasEdits}`)
                return {data: newLangData, modified: hasEdits}
            }else{
                return entry
            }
        });

        setLanguageVariants({...languageVariants, data: updatedLdMap})
    }

    let items : Tab[] = []

    if (languageVariants.data) {
        //console.log(langData)
        items = Object.entries(languageVariants.data).map(([, languageData]) => {
            const {data, modified} = languageData
            return {
                label: <><LanguageFlag lang={data.lang} edited={modified}/></>,
                key: data.id,
                children: (<LanguageCopyEditor languageCopy={data} onSave={(nBDto) => onLangDataSave(nBDto)} onEdit={(eBDto, hasEdits) => onLangDataEdit(eBDto, hasEdits)}/>),
            };
        });
    }

    return (
        <>
        <Button onClick={() => setOpen(true)}><EditOutlined/>Edit</Button>
        {isOpen && (
            <Modal open={isOpen} onOk={() => setOpen(false)} onCancel={() => setOpen(false)} width={1024}>
                <Tabs items={items}/>
            </Modal>
        )}

        </>
    )
}

export default LanguageCopiesEditor