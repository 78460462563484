import React, { FunctionComponent, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiService } from '../service/campaign'
import { RenderDTO } from '../types/campaign'
import { Alert, Button, Tabs } from 'antd'

import styles from './RenderViewer.module.scss'
import LanguageFlag from '../layout/LanguageFlag'

import { Tab } from 'rc-tabs/lib/interface'
import { ArrowLeftOutlined } from '@ant-design/icons'

interface PreviewBuildRendersData {
    loading: boolean
    error: boolean
    renders: RenderDTO[]
}

const TabContent: FunctionComponent<{ html: Document, renderData: RenderDTO }> = ({ html, renderData }) => {
    const { status, message } = renderData;

    const [htmlDoc, setHtmlDoc] = React.useState(html);
    const hasError = status.includes('error')

    const matcher = /<title[^>]*>([^<]+)<\/title>/gm
    const titleResult = matcher.exec(htmlDoc.documentElement.outerHTML)

    return (
        <div className={styles.RenderContainer}>
            {hasError && <Alert message="Error in Rendering" description={message} type="error" showIcon />}
            <div className={styles.Metadata}>
                <h3>HTML Metadata</h3>
                <table className={styles.DlvMetadataTable}>
                    <tr>
                        <td>Subject</td>
                        <td>{titleResult?.[1] ? titleResult?.[1]:"N/A"}</td>
                    </tr>
                </table>
                <LinkAnalyzer html={htmlDoc} />
            </div>
            <div className={styles.Spacer}>&nbsp;</div>
            <HtmlPreviewer html={htmlDoc} />
        </div>
    );
}

const RenderViewer: FunctionComponent = () => {
    const { id, buildid } = useParams()

    const [renderData, setRenderData] = React.useState<PreviewBuildRendersData>({ loading: true, error: false, renders: [] })

    useEffect(() => {
        if (buildid) {
            const API = new ApiService()
            API.getBuildRenders(buildid)
                .then((res) => {
                    //let filteredRender = res.filter(r => r.id == renderid);
                    setRenderData({ loading: false, error: false, renders: res })
                })
                .catch((err) => {
                    console.log(err)
                    setRenderData({ loading: false, error: true, renders: [] })
                })
        }
    }, [buildid])

    let items: Tab[] = []

    if (renderData) {
        items = Object.entries(renderData.renders).map(([key, renderData], index) => {

            const { html } = renderData
            const doc = document.implementation.createHTMLDocument().open()
            doc.write(html)
            doc.close()

            return {
                label: (
                    <>
                        <span style={{ fontSize: 10, textAlign: 'center' }}>{index}.</span>
                        <br />
                        <LanguageFlag lang={renderData.lang} error={renderData.status.includes('error')} />
                    </>
                ),
                key: key,
                children: <TabContent html={doc} renderData={renderData}/>
            }
        })
    }

    const navigate = useNavigate();

    return (
        <div>
            <div className={styles.ButtonDrawer}>
                <Button type={'primary'} icon={<ArrowLeftOutlined />} onClick={() => navigate(`/campaigns/${id}`)}>Go back to Campaign</Button>
                <div style={{flexGrow: 1}}></div>
                {/* {buildid && <Button onClick={() => downloadRenderArchive(buildid, )}><DownloadOutlined />Download Archive</Button>} */}
            </div>
            <h3>Render Preview</h3>
            <Tabs items={items} />  
        </div>
    )
}

export default RenderViewer

/**
 * Utility Components.
 */
const HtmlPreviewer: FunctionComponent<{ html: Document }> = ({ html }) => {
    const renderChild =  <>
        <div style={{width: "100%"}}><iframe srcDoc={html.body.outerHTML} style={{width: "512px", height: "1024px", border: 'solid 1px gray'}}></iframe></div>
    </>

    return (
        <div className={styles.HtmlPreview}>
            <h3>HTML Preview</h3>
            {renderChild}
        </div>
    )
}

const analyzeLinks = (html: Document) => {
    const utm = ['source', 'medium', 'campaign', 'placement']

    const anchor_links = html.getElementsByTagName('a')
    //const emailLinks: { link: string; placement: string; label: string; campaign: string }[] = []

    const placementsArr: any[] = []

    const checkPlacements = (link: string, label: string) => {
        const obj: any = {
            link,
            label,
            utm: {}
        }

        utm.forEach((val) => {
            const componentMatcher = new RegExp(`(?<=(utm_${val})=).*?(?=&|\\s|$)`, 'gm')
            //console.log(componentMatcher.source)
            const componentResult = componentMatcher.exec(link)
            //console.log(componentResult?.[0])
            obj["utm"][val] = componentResult?.[0]
        })
        //console.log(obj)
        placementsArr.push(obj)
    }

    console.log(placementsArr)

    Array.from(anchor_links).forEach((hrefItem) => {
        const hrefAttr = hrefItem.getAttribute('href')
        if (!hrefAttr) {
            console.warn('No href attribute')
            //hrefItem.setAttribute('style', "background-color: 'red'")
        } else {
            //Get utm placement for Element
            const href = hrefItem.getAttribute('href') || ''
            let label = hrefItem.innerText

            if (label.trim() == '') {
                console.log(`no text found for ${hrefItem}`)
                label = /* hrefItem.children[0]?.nodeName.toString() + */ hrefItem.children[0].getAttribute("src")?.toString() || ''
            }
            //console.log(href)
            checkPlacements(href.toString(), label)
        }
    })

    return placementsArr
}

const LinkAnalyzer: FunctionComponent<{ html: Document }> = ({ html: htmlDoc }) => {
    const a = htmlDoc.getElementsByTagName('a')

    const placementsArr = analyzeLinks(htmlDoc)
    console.log(placementsArr);

    const linksNode: React.ReactElement[] = [
        <>
            <tr>
                <th>URL</th>
                <th>Label</th>
                {Object.entries(placementsArr[0].utm).map(([k,v], id) => {
                    return <th key={id}><>{k}</></th>
                })}
            </tr>
        </>
    ]

    placementsArr.map((el) => {
        const { link, label, utm } = el
        linksNode.push(
            <tr>
                <td>{label}</td>
                <td>{link}</td>
                {Object.entries(utm).map(([k,v], id) => {
                    return <td key={id}><>{v}</></td>
                })}
            </tr>
        )
    })

    return (
        <>
            <h3>Links</h3>
            <table className={styles.DlvMetadataTable}>{linksNode}</table>
        </>
    )
}