import React, { FunctionComponent } from 'react'
import { Popover } from 'antd'
import {
    CheckCircleFilled, AlertFilled, LoadingOutlined, WarningFilled
  } from '@ant-design/icons';
import { BriefBuildStatus } from './BriefActions';
import { BuildStepFeedback } from './BuildStepFeedback';

interface BriefBuildDataProps {
    build: BriefBuildStatus
}

const BriefBuildData: FunctionComponent<BriefBuildDataProps> = (props: BriefBuildDataProps) => {
    const {error, loading, build} = props.build
    
    if(loading){
        return <LoadingOutlined />
    }

    if(!build){
        return (
            <Popover content={"No builds yet!"} title="Build Info" placement="topRight">
                <AlertFilled style={{color: 'orange'}} size={100}/>
            </Popover>
        )
    }

    if(error) {
        console.log(error)
        return <WarningFilled style={{color: 'red'}}/>
    }

    if (build && build.build_status.length > 0) {
        
        const lastBuildData = (
            <div>
                <BuildStepFeedback build={build.build_status} direction='horizontal'/>
            </div>
        );

        return <>
            <Popover content={lastBuildData} title="Build successful" placement="topRight">
                <CheckCircleFilled style={{color: 'green'}} size={100}/>
            </Popover>
        </>
    }else{
        return (
            <Popover content={"No builds yet!"} title="Build Info" placement="topRight">
                <AlertFilled style={{color: 'orange'}} size={100}/>
            </Popover>
        )
    }
}

export default BriefBuildData
