import React, { FunctionComponent } from "react";
import { BlocksContent, LanguageDataPayload } from "../../types/payloads";
import { AlertOutlined, SaveOutlined, StopOutlined } from "@ant-design/icons";
import { Alert, Button, Flex, Space } from "antd";
import { ApiService } from "../../service/campaign";
import { Editor } from "@monaco-editor/react";
import { MONACO_EDITOR_OPTIONS } from "../../utility/editorConfig";
import { isEqual } from 'lodash';

export interface LanguageCopyEditMap {
    data: LanguageDataPayload
    modified: boolean
}

interface LanguageCopyEditorProps {
    languageCopy: LanguageDataPayload
    onSave: (newPayload: LanguageDataPayload) => void
    onEdit: (newPayload: LanguageDataPayload, hasEdits: boolean) => void
}

const LanguageCopyEditor: FunctionComponent<LanguageCopyEditorProps> = (props: LanguageCopyEditorProps) => {
    //The Initial State
    const [originalData, setOriginalData] = React.useState<LanguageDataPayload>(props.languageCopy)
    const [editorData, setEditorData] = React.useState<LanguageDataPayload>(props.languageCopy)

    //Json Parse error
    const [parseError, setParseError] = React.useState<boolean>(false);

    //Checks if there are edits.
    const hasEdits = !isEqual(originalData, editorData);

    //console.log(modified)

    const onLangCopySave = () => {
        const {build_id, lang} = editorData
        const newData : LanguageDataPayload = {...editorData, json: editorData.json};
        
        const API = new ApiService();
        API.updateLanguageCopy(build_id, lang, newData)
            .then((resp) => {
                console.debug(resp)
                console.log(`Updated ${resp.id}`)
                props.onSave(resp)
                setOriginalData(resp)
            })
            .catch((err) => {
                console.error(err)
            })

    }

    const onEditorValueChange = (inputValue: string | undefined) => {
        if (inputValue) {
            try {
                const parsedBlocks = JSON.parse(inputValue) as BlocksContent

                const newData : LanguageDataPayload = {...editorData, json: parsedBlocks};
                const hasEdits = !isEqual(originalData, newData);
                
                setParseError(false)
                setEditorData(newData)
                //Checks if there are edits.
                props.onEdit(newData, hasEdits)

            }catch(jsonParseError){
                console.warn("JSON is not valid!" + jsonParseError)
                setParseError(true)
            }
        }else{
            console.warn("Value is null!")
        }
    }

    const onEditorReset = () => {
        setEditorData(originalData)
        props.onEdit(originalData, false)
    }

    const parseEditorData = () => {
        return JSON.stringify(editorData.json, null, 2)   
    }

    return (
    <Flex gap={'middle'} vertical>
        <Space>
            <Button disabled={!hasEdits} onClick={() => onLangCopySave()}><SaveOutlined /> Save</Button>
            <Button disabled={!hasEdits} onClick={() => onEditorReset()}><StopOutlined /> Revert Changes</Button>
        </Space>
        {parseError && <Alert type="warning" message={<><AlertOutlined/> Error with the JSON Syntax.</>} />}
        {editorData && 
            <Editor
                language="json"
                theme="vs-dark"
                options={MONACO_EDITOR_OPTIONS}
                value={parseEditorData()}
                onChange={onEditorValueChange}
                height={512}
            />
        }
    </Flex>);
}
 
export default LanguageCopyEditor;