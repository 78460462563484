import React, { FunctionComponent } from 'react'
import { CampaignDTO } from '../../types/campaign'
import { Descriptions } from 'antd'
import Title from 'antd/es/typography/Title'
import { TicketEditor } from './table/CampaignTableColumns'

interface CampaignResumeProps {
    campaign: CampaignDTO
}

const CampaignMetadata: FunctionComponent<CampaignResumeProps> = (props: CampaignResumeProps) => {
    const { brand, briefs } = props.campaign

    const items = [
        {
            label: "Brand",
            children: <b>{brand}</b>
        },
        {
            label: 'FTP Path',
            children: <b>{props.campaign.ftp_path}</b>
        },
        {
            label: "Ticket",
            children: <b><TicketEditor {...props.campaign}/></b>
        },        
        {
            label: "Operation",
            children: <b>{props.campaign.operation_id}</b>
        },
        {
            label: "Category",
            children: <b>{props.campaign.category}</b>
        },
        {
          label: 'Briefs',
          children: <>
                    {briefs && <>
                        {briefs.length} 
                        {briefs.length > 0 ? 
                            <>(Last: {briefs.reduce(function (a, b) { return a.created_at > b.created_at ? a : b; }).created_at})</>:
                            <></>
                        }
                        </>
                    }
                    </>
        },
    ]

    return (
        <>
            <Title level={4}>Campaign Metadata</Title>
            <Descriptions title={''} items={items} /* layout="vertical" */ bordered column={1}/>
        </>
    )
}
export default CampaignMetadata
