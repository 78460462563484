import React, { FunctionComponent } from "react";
import { CampaignDTO } from "../../../types/campaign";
import { Tag } from "antd";
import TicketEditor from "../../integration/TicketEditor";

/**
 * Column that shows ticket Id and shows AddTicket Component if not available.
 */
interface TicketColumnProps {
    id: string,
    campaign: CampaignDTO,
    issue: string
}

const TicketColumn: FunctionComponent<TicketColumnProps> = ({campaign}: TicketColumnProps) => {
    return (<TicketEditor {...campaign}/>);
}

/**
 * Column that shows brand information.
 */
const BrandColumn: FunctionComponent<CampaignDTO> = ({brand}: CampaignDTO) => {
    const color = brand.toUpperCase() == 'DIESEL' ? 'red' : 'blue'
    return (
        <>
            <Tag color={color} key={brand}>
                {brand.toUpperCase()}
            </Tag>
        </>
    )
}
 
export { BrandColumn, TicketColumn, TicketEditor };
