import axios, { AxiosResponse } from "axios";
import { BriefDTO, CampaignDTO, CampaignProcessorResponsePayload, BriefBuildDTO, DeliveryRenderStep, RenderDTO, CampaignAPIResponse, CommonDataDTO } from "../types/campaign";
import { BlockDTO } from "../components/blocks/BlockGalleryComponent";
import { BlockMetadata, BuildLanguageDataPayload, BuilderPayload, LanguageDataPayload } from "../types/payloads";
import { CampaignMetadata } from "../components/campaign/CreateCampaignButton";

export class ApiService {
    apiInstance;

    constructor() {

        this.apiInstance = axios.create({
            baseURL: '/api/v1',
            //timeout: 10000,
            headers: { 'X-Frontend': 'campaignly' },
        });

        this.apiInstance.interceptors.response.use((response) => {
            if (response.status == 204) {
                console.debug(`Intercepted no content [${response.status}]`)
                //return Promise.resolve([])
            }
            return response;
        });
    }

    getAllCampaigns(): Promise<CampaignDTO[]> {
        return new Promise<CampaignDTO[]>((resolve, reject) => {
            this.apiInstance.get(`/campaigns`)
                .then(value => {
                    console.debug("Got Campaigns Successfully!");
                    console.debug(value.data);
                    resolve(<CampaignDTO[]>value.data.data);
                })
                .catch(error => {
                    console.debug("API Error: ", error);
                    reject(error);
                });
        });
    }

    createNewCampaign(campaign: CampaignMetadata): Promise<CampaignDTO> {
        return new Promise<CampaignDTO>((resolve, reject) => {
            this.apiInstance.post(`/campaigns`, campaign)
                .then(value => {
                    resolve(<CampaignDTO>value.data.data);
                })
                .catch(error => {
                    console.debug("API Error: ", error);
                    reject(error);
                });
        });
    }

    getCampaignById(id: string): Promise<CampaignDTO> {
        return new Promise<CampaignDTO>((resolve, reject) => {
            this.apiInstance.get(`/campaigns/${id}`)
                .then(value => {
                    resolve(<CampaignDTO>value.data.data);
                })
                .catch(error => {
                    console.debug("API Error: ", error);
                    reject(error);
                });
        });
    }

    getUploadsByCampaignId(id: string): Promise<BriefDTO[]> {
        return new Promise<BriefDTO[]>((resolve, reject) => {
            this.apiInstance.get(`/campaigns/${id}/brief`)
                .then(value => {
                    resolve(<BriefDTO[]>value.data.data);
                })
                .catch(error => {
                    console.debug("API Error: ", error);
                    reject(error);
                });
        });
    }

    getBlocks(brand: string): Promise<BlockDTO[]> {
        return new Promise<BlockDTO[]>((resolve, reject) => {
            this.apiInstance.get(`/blocks/${brand}`)
                .then(value => {
                    resolve(<BlockDTO[]>value.data.blocks);
                })
                .catch(error => {
                    console.debug("API Error: ", error);
                    reject(error);
                });
        });
    }

    getBrands(): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            this.apiInstance.get(`/blocks/brands`)
                .then(value => {
                    resolve(<string[]>value.data.blocks);
                })
                .catch(error => {
                    console.debug("API Error: ", error);
                    reject(error);
                });
        });
    }

    buildCampaign(processData: BuilderPayload): Promise<CampaignProcessorResponsePayload> {

        if (processData.FtpFolder.toLowerCase().startsWith("marni")) {
            processData.Brand = "marni"
        } else {
            processData.Brand = "diesel"
        }

        return new Promise<CampaignProcessorResponsePayload>((resolve, reject) => {
            this.apiInstance.post<CampaignProcessorResponsePayload>('/campaign', processData).then((resp) => {
                if (resp.status === 200) {
                    console.debug('Process Submitted')
                    resolve(resp.data)
                } else {
                    console.warn("Error!", resp)
                    reject(resp)
                }
            })
                .catch(error => reject(error))
        })

    }

    uploadBrief(campaign_id: string, file: File): Promise<BriefDTO> {

        return new Promise<BriefDTO>((resolve, reject) => {
            // Create an object of formData
            const formData = new FormData()

            // Update the formData object
            formData.append('file', file, file.name)

            // Details of the uploaded file
            console.debug(file)

            this.apiInstance.post<BriefDTO>(`/campaigns/${campaign_id}/brief`, formData).then((resp) => {
                if (resp.status === 200) {
                    console.debug('File uploaded')
                    resolve(resp.data)
                } else {
                    console.warn("Error!", resp)
                    reject(resp)
                }
            })
                .catch(error => reject(error))
        })
    }

    getLastBriefBuild(campaign_id: string, brief_id: string): Promise<BriefBuildDTO> {
        return new Promise<BriefBuildDTO>((resolve, reject) => {
            this.apiInstance.get<{ data: BriefBuildDTO[] }>(`/campaigns/${campaign_id}/brief/${brief_id}/builds`).then((resp) => {
                const bbDTO: BriefBuildDTO = {
                    brief_id: brief_id,
                    build_status: [],
                    created_at: new Date(),
                    commonData: { head: [], utm: {} },
                    id: ""
                }

                //console.debug(`Got brief builds Successfully for [${brief_id}]!`)
                const respbody = resp.data.data[0];
                console.debug(respbody)
                const status = respbody.build_status as DeliveryRenderStep[];
                const timestamp = new Date(respbody.created_at);

                console.debug(respbody.commonData)

                bbDTO.build_status = status;
                bbDTO.id = respbody.id
                bbDTO.commonData = respbody.commonData as CommonDataDTO
                bbDTO.created_at = new Date(timestamp)

                //console.debug(respbody)
                //Call Father compoentne
                resolve(bbDTO)
            })
                .catch(error => reject(error))
        })

    }

    getBriefBuilds(campaign_id: string, brief_id: string): Promise<BriefBuildDTO[]> {
        return new Promise<BriefBuildDTO[]>((resolve, reject) => {
            this.apiInstance.get<{ data: BriefBuildDTO[] }>(`/campaigns/${campaign_id}/brief/${brief_id}/builds`)
                .then((resp) => {
                    resolve(resp.data.data as BriefBuildDTO[])
                })
                .catch(error => reject(error))
        })

    }

    buildBrief(campaign_id: string, brief_id: string): Promise<BriefBuildDTO> {

        return new Promise<BriefBuildDTO>((resolve, reject) => {
            this.apiInstance.post<BriefBuildDTO>(`/campaigns/${campaign_id}/brief/${brief_id}`).then((resp) => {
                if (resp.status === 200) {
                    resolve(resp.data as BriefBuildDTO)
                } else {
                    console.warn("Error!", resp)
                    reject(resp)
                }
            })
                .catch(error => reject(error))
        })

    }

    getBuildRenders(build_id: string): Promise<RenderDTO[]> {
        return new Promise<RenderDTO[]>((resolve, reject) => {
            this.apiInstance.get<CampaignAPIResponse<RenderDTO[]>>(`/renders/build/${build_id}`).then((resp) => {
                if (resp.status == 204) {
                    console.debug(`No brief build renders found for [${build_id}]!`)
                    resolve([])
                }
                resolve(resp.data.data as RenderDTO[])
            })
                .catch(error => reject(error))
        })

    }


    getBlockPreview(brand: string, block: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.apiInstance.get<string>(`/blocks/${brand}/render/${block}`).then((resp) => {
                if (resp.status == 204) {
                    console.debug(`No brief build renders found for [${block}]!`)
                    reject("")
                }
                resolve(resp.data as string)
            })
                .catch(error => reject(error))
        })

    }

    getBuildsByCampaignId(campaign_id: string): Promise<BriefBuildDTO[]> {
        return new Promise<BriefBuildDTO[]>((resolve, reject) => {
            this.apiInstance.get<{ data: BriefBuildDTO[] }>(`/campaigns/${campaign_id}/builds`).then((resp) => {
                if (resp.status == 204) {
                    console.debug(`No brief builds found for [${campaign_id}]!`)
                    resolve([])
                }
                resolve(resp.data.data as BriefBuildDTO[])
            })
                .catch(error => reject(error))
        })

    }

    //Service Section
    getRawLanguageDataByBuildId(build_id: string): Promise<BuildLanguageDataPayload> {
        return new Promise<BuildLanguageDataPayload>((resolve, reject) => {
            this.apiInstance.get<{ data: { blocks: BlockMetadata[]; data: Map<string, string>; } }>(`/service/convert/builds/${build_id}/raw`).then((resp) => {
                if (resp.status == 204) {
                    console.debug(`No converted LanguageData builds found for [${build_id}]!`)
                    reject([])
                }
                resolve(resp.data as BuildLanguageDataPayload)
            })
                .catch(error => reject(error))
        })

    }

    //Service Section
    getLanguageDataByBuildId(build_id: string): Promise<LanguageDataPayload[]> {
        return new Promise<LanguageDataPayload[]>((resolve, reject) => {
            this.apiInstance.get<LanguageDataPayload[]>(`/service/convert/builds/${build_id}`).then((resp) => {
                if (resp.status == 204) {
                    console.debug(`No converted LanguageData builds found for [${build_id}]!`)
                    reject([])
                }
                resolve(resp.data as LanguageDataPayload[])
            }).catch((error) => {
                console.debug(error)
                reject(error)
            })
        })

    }

    //Service Section
    createBuildFromBriefFile(build_id: string): Promise<BriefBuildDTO> {
        return new Promise<BriefBuildDTO>((resolve, reject) => {
            this.apiInstance.post<BriefBuildDTO>(`/service/convert/${build_id}`).then((resp) => {

                if (resp.status == 204) {
                    console.debug(`No converted LanguageData builds found for [${build_id}]!`)
                    reject([])
                }

                //console.debug(`Got brief builds Successfully for [${brief_id}]!`)
                const respbody = resp.data as BriefBuildDTO;
                console.debug(respbody)
                //const status = respbody.build_status as CampaignProcessorStepPayload[];
                //const timestamp = new Date(respbody.created_at);

                //Call Father compoentne
                resolve(respbody)
            })
                .catch(error => reject(error))
        })

    }

    //Service Section
    cloneBuildById(build_id: string): Promise<BriefBuildDTO> {
        return new Promise<BriefBuildDTO>((resolve, reject) => {
            this.apiInstance.post<BriefBuildDTO>(`/service/variant/frombuild/${build_id}`).then((resp) => {

                if (resp.status == 204) {
                    console.debug(`No converted LanguageData builds found for [${build_id}]!`)
                    reject([])
                }

                //console.debug(`Got brief builds Successfully for [${brief_id}]!`)
                const respbody = resp.data as BriefBuildDTO;
                console.debug(respbody)
                //const status = respbody.build_status as CampaignProcessorStepPayload[];
                //const timestamp = new Date(respbody.created_at);

                //Call Father compoentne
                resolve(respbody)
            })
                .catch(error => reject(error))
        })

    }

    //Delete a build by its Id
    deleteBuildById(campaign_id: string, build_id: string): Promise<null> {
        return new Promise<null>((resolve, reject) => {
            this.apiInstance.delete<null>(`/campaigns/${campaign_id}/builds/${build_id}`).then((resp) => {

                if (resp.status == 204) {
                    console.debug(`No build was deleted with ID [${build_id}]!`)
                    reject([])
                }

                //Call Father compoentne
                resolve(null)
            })
                .catch(error => reject(error))
        })

    }

    /**
     * Method which, starting from LanguageData Object, is entitled to building it.
     * @param build_id 
     * @returns 
     */
    renderLangDataByBuildId(build_id: string): Promise<BriefBuildDTO> {
        return new Promise<BriefBuildDTO>((resolve, reject) => {
            this.apiInstance.post<BriefBuildDTO>(`/service/render/${build_id}`).then((resp) => {

                if (resp.status == 204) {
                    console.debug(`No converted LanguageData builds found for [${build_id}]!`)
                    reject([])
                }

                //console.debug(`Got brief builds Successfully for [${brief_id}]!`)
                const respbody = resp.data as BriefBuildDTO;
                console.debug(respbody)
                //const status = respbody.build_status as CampaignProcessorStepPayload[];
                //const timestamp = new Date(respbody.created_at);

                //Call Father compoentne
                resolve(respbody)
            })
            .catch(error => reject(error))
        })

    }

    updateLanguageCopy(build_id: string, language: string, payload: LanguageDataPayload): Promise<LanguageDataPayload> {
        return new Promise<LanguageDataPayload>((resolve, reject) => {
            this.apiInstance.patch<LanguageDataPayload>(`/convert/builds/${build_id}/locale/${language}`, payload).then((resp) => {

                if (resp.status == 204) {
                    console.debug(`No brief build renders found for [${build_id}]!`)
                    resolve(payload)
                }

                //console.debug(`Got brief builds Successfully for [${brief_id}]!`)
                const respbody = resp.data as LanguageDataPayload;
                console.debug(respbody)

                resolve(respbody)
            })
                .catch(error => reject(error))
        })

    }

    downloadZipByBuildId(build_id: string) {
        return new Promise<AxiosResponse>((resolve, reject) => {
            this.apiInstance.get(`/download/builds/${build_id}`, { responseType: 'blob' })
                .then((response) => {
                    resolve(response)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }

    //Edit Campaign data by Id
    editCampaignById(campaign_id: string, data: CampaignMetadata): Promise<CampaignDTO> {
        return new Promise<CampaignDTO>((resolve, reject) => {
            this.apiInstance.patch<CampaignAPIResponse<CampaignDTO>>(`/campaigns/${campaign_id}`, data).then((resp) => {
                const respbody = resp.data.data as CampaignDTO;
                console.log(respbody)
                resolve(respbody)
            })
            .catch(error => reject(error))
        })

    }

}

export default new ApiService();