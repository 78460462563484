import React, { FunctionComponent, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ApiService } from '../service/campaign'
import { CampaignDTO } from '../types/campaign'
import BriefList from '../components/brief/CampaignBriefData'
import { Flex } from 'antd'
import BriefUploadDragger from '../components/uploader/BriefUploadDragger'
import Title from 'antd/es/typography/Title'
import CampaignBuilds from '../components/builds/CampaignBuilds'
import CampaignMetadata from '../components/campaign/CampaignMetadata'
import { Helmet } from 'react-helmet'

import styles from './CampaignDetails.module.scss'
import Container, { FlexContainer } from '../layout/Container'
import CampaignToolbar from '../components/campaign/toolbar/CampaignToolbar'

const CampaignDetailPage: FunctionComponent = () => {
    const { id } = useParams()

    const [campaignData, setCampaignData] = React.useState<CampaignDTO | null>(null)
    //const [briefUploads, setBriefUploads] = React.useState<BriefDTO[]>([])
    const [lastBriefDate, setLastBriefDate] = React.useState<Date>(new Date())
    const [error, setError] = React.useState('')

    const updateCampaign = (id: string) => {
        const API = new ApiService()
        API.getCampaignById(id)
            .then((res) => {
                setCampaignData(res)
            })
            .catch((err) => {
                setError(err.message)
            })
    }

    useMemo(() => {
        console.log(`Triggered memo with ${lastBriefDate}.`)
        if (!id) {
            setError('Missing id value!')
        } else {
            updateCampaign(id)
        }
    }, [id, lastBriefDate])

    const updateBriefs = (/* _bud: BriefDTO */) => {
        setLastBriefDate(/* _bud.created_at */ new Date())
    }

    return (
        <>
            <Helmet>
                <title>Campaign Details - {`${campaignData?.name}`}</title>
            </Helmet>
            {/*<Descriptions title="Campaign Description" bordered column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 }} items={items} />*/}
            <div style={{ width: '100%' }}>
                {campaignData && <CampaignToolbar campaign={campaignData} onEdit={(cDto) => setCampaignData(cDto)} />}
                <FlexContainer>
                    <Container>
                        <Flex vertical>
                            {campaignData && (<CampaignMetadata campaign={campaignData} />)}
                        </Flex>
                    </Container>
                    <Container>
                        {campaignData && (
                            <div className={styles.DetailContainer}>
                                <Title level={4}>Build History</Title>
                                <CampaignBuilds campaign={campaignData} lastUpdate={lastBriefDate} />
                            </div>
                        )}
                    </Container>
                    <Container>
                        {campaignData && (
                            <div>
                                <Title level={4}>Brief Management</Title>
                                <BriefUploadDragger campaign={campaignData} onUploadComplete={updateBriefs} />
                                {campaignData.briefs && <BriefList campaign={campaignData} briefs={campaignData.briefs} onBuildUpdate={() => updateBriefs()} />}
                            </div>
                        )}
                    </Container>
                </FlexContainer>
            </div>
        </>
    )
}

export default CampaignDetailPage
