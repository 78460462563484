import React, { FunctionComponent, useEffect } from 'react'
import { BriefBuildDTO, RenderDTO } from '../../types/campaign'
import { ApiService } from '../../service/campaign'
import { Flex } from 'antd'
import LanguageFlag from '../../layout/LanguageFlag'

interface CampaignBuildsProps {
    build: BriefBuildDTO
}



const ConvertLanguageData: FunctionComponent<CampaignBuildsProps> = (props: CampaignBuildsProps) => {
    const [languageData, setLanguageData] = React.useState<{ loading: boolean; error: boolean; langData: RenderDTO[] }>({ loading: true, error: false, langData: [] })

    const { langData } = languageData

    useEffect(() => {
        const API = new ApiService()
        API.getBuildRenders(props.build.id)
            .then((success) => {
                console.debug(success)
                //const last5 = success.slice(0, 3)
                
                setLanguageData({ loading: false, error: false, langData: success })
            })
            .catch((error) => {
                console.error('error!', error)
                setLanguageData({ loading: false, error: true, langData: [] })
            })
    }, [props.build])

    return (
        <Flex gap={'middle'}>
        {Object.entries(langData).map(([_k, v], i) => {
            return (
                <div key={i} title={v.message}>
                    <LanguageFlag lang={v.lang} error={v.status.includes("error")}/>
                </div>
            )
        })}
        </Flex>
    )
}

export default ConvertLanguageData
