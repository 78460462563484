import React, { FunctionComponent } from 'react'
import { BriefDTO, CampaignDTO } from '../../types/campaign'
import { UploadProps, message } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import { FileExcelOutlined } from '@ant-design/icons'
import { ApiService } from '../../service/campaign'

interface BriefUploadDraggerProps {
    campaign: CampaignDTO
    onUploadComplete: (response: BriefDTO) => void
}

const BriefUploadDragger: FunctionComponent<BriefUploadDraggerProps> = (props: BriefUploadDraggerProps) => {
    const [error, setError] = React.useState('')

    const uploadFile = async (options: any) => {
        const { onSuccess, onError, file, filename } = options;

        if (filename && !filename.indexOf(".xlsx")) {
            onError("Extension not accepted!")
        }

        console.log('Uploading file', file)
        const API = new ApiService()
        API.uploadBrief(props.campaign.id, file)
            .then((response) => {
                props.onUploadComplete(response)
                onSuccess(response, file);
            })
            .catch((err) => {
                setError(err.message)
                console.warn('Error!', err)
                onError(err)
            })

    }

    const draggerProps: UploadProps = {
        name: 'file',
        method: 'POST',
        maxCount: 1,
        multiple: false,
        //customRequest: 'http://localhost:8080/api/v1/campaigns/333/brief',
        onChange(info) {
            const { status, error } = info.file
            if (status !== 'uploading') {
                console.log(info.file, info.fileList)
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`)
                //props.onUploadComplete();
            } else if (status === 'error') {
                if (error.data && error.data.message){
                    message.error(`${info.file.name} file upload failed: ${error.data.message}`)
                }else{
                    console.warn(error)
                    message.error(`Unknown upload error! ${error.response.data.message}`)
                }
            }
        }
    }

    return (
        <>
        <Dragger {...draggerProps} style={{width: '250px', maxHeight: '128px', margin: 'auto', marginTop: '15px', marginBottom: '15px', display: 'block !important'}} customRequest={uploadFile}>
            <div style={{display: 'flex'}}>
            <p className="ant-upload-drag-icon" style={{maxWidth: '64px'}}>
                <FileExcelOutlined size={1}/>
            </p>
            <p style={{textAlign: 'left'}}>Click or drag to upload a brief file</p>
            </div>
        </Dragger>
        *only .xlsx sheets supported. single file only.
        </>
    )
}

export default BriefUploadDragger
