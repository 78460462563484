import { CopyOutlined, CodeOutlined, InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons'
import { Flex, Popover, Space, message } from 'antd'
import dayjs from 'dayjs'
import React, { FunctionComponent, useRef, useState } from 'react'
import LanguageCopiesEditor from './LanguageCopiesEditor'
import { BriefBuildDTO, CampaignDTO } from '../../types/campaign'
import { ApiService } from '../../service/campaign'
import PreviewBuildRenders from './PreviewBuildRenders'
import { downloadRenderArchive } from '../../utility/campaignRender'
import { Button } from 'primereact/button'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { Toast } from 'primereact/toast'

import styles from './BuildActions.module.scss';

interface CampaignBuildDataProps {
    buildData: BriefBuildDTO
    parentCampaign: CampaignDTO
    isBuilt: boolean
    onClone: (sourceCopy: BriefBuildDTO) => void
    onUpdate: (newStatus: BriefBuildDTO) => void
    onDelete: (build: BriefBuildDTO) => void
}

const BuildActions: FunctionComponent<CampaignBuildDataProps> = (props: CampaignBuildDataProps) => {
    const API = new ApiService()
    const { isBuilt, buildData } = props
    const [messageApi, contextHolder] = message.useMessage()

    const buildConvertedData = (sourceBuild: BriefBuildDTO) => {
        if (sourceBuild.build_status.map((psp) => psp.name).includes('build')) {
            alert('Già buildata! Crea una nuova variante e buildati quella.')
        } else {
            messageApi.open({
                type: 'loading',
                content: 'Build in progress...',
                duration: 0
            })

            //alert("Sto buildando questa robaccia.")
            API.renderLangDataByBuildId(sourceBuild.id)
                .then((buildDTO) => {
                    messageApi.destroy()
                    messageApi.open({
                        type: 'success',
                        content: 'Build completed successfully!',
                        duration: 10
                    })
                    props.onUpdate(buildDTO)
                })
                .catch((err) => {
                    console.log(err.response.data.error.message)
                    messageApi.destroy()
                    messageApi.open({
                        type: 'error',
                        content: `Error during build: ${err.response.data.error.message}`,
                        duration: 5
                    })
                    //alert("Errore a buildare")
                })
        }
    }

    return (
        <>
            {contextHolder}
            <Space>
                <Flex vertical gap={'small'} justify={'flex-start'} style={{gap: "5px"}} align="flex-start">
                    <Space>
                        {/* //If the campaign is already built, Make available the Approve and Edit Button */}
                        {isBuilt && <BuiltCampaignActions {...props} />}
                        {(props.buildData.build_status[0].error || !props.buildData.build_status[0].data) && <ConverterErrorActions {...props} />}
                        {/* //If the campaign is not built yet, Make available the Render and Edit (New Variant) Button */}
                        {!isBuilt && !props.buildData.build_status[0].error && props.buildData.build_status[0].data && 
                            (
                                <>
                                    <Button onClick={() => buildConvertedData(buildData)} size="small">
                                        <CodeOutlined />
                                        Render
                                    </Button>
                                    <LanguageCopiesEditor sourceBuild={buildData} />
                                </>
                            )
                        }

                        <Popover
                            title={`The Render Command runs the email building phase based on the data provided in the Convert/Clone Phase and renders email`}
                        >
                            <InfoCircleOutlined />
                        </Popover>
                    </Space>
                    <small style={{ textAlign: 'left' }}>
                        {dayjs(buildData.created_at).fromNow()} / {dayjs(buildData.created_at).format('YYYY-MM-DD HH:mm')}
                    </small>
                </Flex>
            </Space>
        </>
    )
}

export default BuildActions

/**
 * Button drawer for Actions to execute when a campaign is built without errors:
 * Preview, Download and Clone.
 * @param props
 * @returns
 */
const BuiltCampaignActions: FunctionComponent<CampaignBuildDataProps> = (props: CampaignBuildDataProps) => {
    const { buildData } = props
    const API = new ApiService()
    /**
     * Clone the LanguageData and CommonData of a build, to create a new one in order to have it as a starting point for Edits.
     * The behaviour should include a modal window in Which the user can add a comment or select the languages to be edited.
     * @param sourceBuild
     */
    const createNewLanguageDataVariant = (sourceBuild: BriefBuildDTO) => {
        API.cloneBuildById(sourceBuild.id)
            .then((dtoData) => {
                props.onUpdate(dtoData)
                //setBuildData({...buildData, builds: [dtoData, ...builds]})
            })
            .catch((err) => {
                alert(err)
            })
    }

    return (
        <div className={styles.ActionButtons}>
            <PreviewBuildRenders build={buildData} />
            <Button size="small" onClick={() => downloadRenderArchive(buildData.id, props.parentCampaign.name)}>
                <DownloadOutlined />
                Download
            </Button>
            <Button size="small" onClick={() => createNewLanguageDataVariant(buildData)}>
                <CopyOutlined />
                Clone
            </Button>
        </div>
    )
}

const ConverterErrorActions: FunctionComponent<CampaignBuildDataProps> = (props: CampaignBuildDataProps) => {
    const toast = useRef<Toast>(null);
    const [visible, setVisible] = useState(false);

    const accept = () => {
        const API = new ApiService();
        API.deleteBuildById(props.parentCampaign.id, props.buildData.id).then(() => {
            if(toast.current){
                toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'Build was deleted', life: 3000 });
            }
            props.onDelete(props.buildData)
        })
        .catch((error) => {
            if(toast.current){
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while deleting build!', life: 3000 });
            }
        })
    }

    const reject = () => {
        if(toast.current){
            //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
    }

    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message="Are you sure you want to proceed?<br>This action is irreversible." 
                header="Deleting Build" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} />
            <Button icon="pi pi-trash" size="small" onClick={() => setVisible(true)} severity="danger">
                Delete
            </Button>
        </>
    )
}

//export default BuiltCampaignActions;
