import React, { FunctionComponent } from 'react'
import { BriefBuildDTO, BriefDTO, CampaignDTO } from '../../types/campaign'
import { Avatar, List, Skeleton } from 'antd'
import BriefActions from './BriefActions'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs' // ES 2015
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export interface BriefProps {
    //campaignId: string,
    campaign: CampaignDTO
    briefs: BriefDTO[]
    onBuildUpdate: (bbDto: BriefBuildDTO) => void
}

/**
 * This component renders briefs for a campaign
 * @param props 
 * @returns 
 */
const BriefList: FunctionComponent<BriefProps> = (props: BriefProps) => {
    // const datestring = (d: Date) => {
    //     console.log(typeof d)
    //     return d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear() + ' ' + d.getHours() + ':' + /*(d.getMinutes() < 10) ? "0":"" +*/ d.getMinutes()
    // }

    const sortedBriefs = props.briefs.sort((a,b) => dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? 1:0)

    return (
        <>
            <List
                className="demo-loadmore-list"
                loading={false}
                itemLayout="vertical"
                //loadMore={loadMore}
                dataSource={sortedBriefs}
                renderItem={(briefEntry) => {
                    const createdAt = dayjs(briefEntry.created_at)

                    return (
                        <List.Item actions={[
                                <BriefActions brief={briefEntry} key={briefEntry.id} onBuildChange={(build) => props.onBuildUpdate(build)}/>
                            ]}>
                            <Skeleton avatar title={false} loading={false} active>
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            src={`/logos/${props.campaign.brand.toLowerCase()}.jpg`}
                                        />
                                    }
                                    title={
                                        <Link to={`/campaigns/${props.campaign.id}/brief/${briefEntry.id}`}>{briefEntry.name}</Link>
                                    }
                                    description={
                                        <>
                                            <small>{briefEntry.checksum.substring(briefEntry.checksum.length-10, briefEntry.checksum.length)}</small> -
                                            <small> <b>uploaded:</b> {createdAt.fromNow()} / {createdAt.format("YYYY-MM-DD HH:mm")}</small>
                                            <br />
                                            {/* <small>Last Built: {build.created_at.toString()}</small> */}
                                        </>
                                    }
                                />
                                <div>{briefEntry.comment}</div>
                            </Skeleton>
                        </List.Item>
                    )
                }}
            />
        </>
    )
}

export default BriefList
