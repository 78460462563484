import React, { useEffect } from 'react'
import { Alert, Flex } from 'antd'
import { FunctionComponent } from 'react'
import { ApiService } from '../../service/campaign'
import { Dropdown } from 'primereact/dropdown'

export interface BlockDTO {
    name: string
    lmd: string
}

interface BrandChooserProps {
    onBrandChanged: (brand: string) => void
}

const BrandChooserComponent: FunctionComponent<BrandChooserProps> = (props: BrandChooserProps) => {
    const [brand, setBrand] = React.useState<string>('');
    const [data, setData] = React.useState<string[]>([])
    const [error, setError] = React.useState('')

    useEffect(() => {
        const API = new ApiService()
        API.getBrands()
            .then((res) => {
                setData(res)
            })
            .catch((err) => {
                setError(err.message)
            })
    }, [])

    const handleChange = (value: string) => {
        console.log(`selected ${value}`)
        setBrand(value)
        props.onBrandChanged(value)
    }

    if (data.length > 0 && !error) {
        return (
            <Flex wrap="wrap" gap="small" justify="center">
                <Dropdown
                    defaultValue=""
                    //size={'middle'}
                    value={brand}
                    style={{ width: '100%' }}
                    onChange={(e) => handleChange(e.target.value)}
                    options={data.map((brand) => {
                        return { value: brand, label: brand }
                    })}
                />
            </Flex>
        )
    } else {
        return (
            <>
                {error && <Alert message="Error" description="Error while loading data!" type="error" showIcon />}
                {!error && 'Nothing to show!'}
            </>
        )
    }
}

export default BrandChooserComponent
