import React, { FunctionComponent, useEffect } from 'react'
import { App, Button, Space } from 'antd'
import { BriefBuildDTO, BriefDTO } from '../../types/campaign'
import BriefBuildData from './BriefBuildData'
import { ApiService } from '../../service/campaign'
import axios from 'axios'
import { CloudDownloadOutlined, FileAddOutlined } from '@ant-design/icons'

interface BriefActionsProps {
    brief: BriefDTO,
    onBuildChange: (build: BriefBuildDTO) => void
}

export interface BriefBuildStatus {
    loading: boolean,
    error: boolean,
    build: BriefBuildDTO | null
}

const BriefActions: FunctionComponent<BriefActionsProps> = (props: BriefActionsProps) => {

    const [buildData, setBuildData] = React.useState<BriefBuildStatus>({loading: true, error: false, build: null})
    const {loading, build} = buildData;
    const {message} = App.useApp();

    const buildBrief = (brief: BriefDTO) => {
        const API = new ApiService()
        setBuildData({...buildData, loading: true})

        //API.buildBrief(props.brief.campaign_id, brief.id)
        API.createBuildFromBriefFile(brief.id)
            .then((dto) => {
                setBuildData({...buildData, loading: false, build: dto, error: false})
                props.onBuildChange(dto)
            })
            .catch((_error) => {
                setBuildData({...buildData, loading: false, error: true})
            })
    }

    const downloadFile = (brief: BriefDTO) => {
        //window.open(`/api/v1/campaigns/${brief.campaign_id}/brief/${brief.id}/download`, "_blank");
        axios.get(`/api/v1/campaigns/${brief.campaign_id}/brief/${brief.id}/download`, { responseType: 'blob' })
            .then(function (response) {
                const fileName = response.headers['content-disposition'].split('filename=')[1];
                const url = window.URL.createObjectURL(
                    new Blob([response.data] , { type: 'application/vnd.ms-excel' })
                )
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
            })
            .catch((error) => {
                console.log(error.response.status)
                if(error.response.status == 404){
                    message.error("Internal error. Brief not found.")
                }
            })
    }

    useEffect(() => {
        const API = new ApiService()
        API.getLastBriefBuild(props.brief.campaign_id, props.brief.id)
            .then((success) => {
                //console.log(success)
                setBuildData({loading: false, error: false, build: success})
            })
            .catch((error) => {
                console.error("error!", error)
                setBuildData({loading: false, error: true, build: null})
            })
    }, [props.brief])

    return (
        <Space>
            <BriefBuildData key={props.brief.id} build={buildData} />
            <Button onClick={() => buildBrief(props.brief)} loading={loading}>
                <FileAddOutlined />
                Create a Build
            </Button>
            {build && 
                <Button onClick={() => downloadFile(props.brief)}>
                    <CloudDownloadOutlined />
                    Download
                </Button>
            }
        </Space>
    )
}

export default BriefActions
