import React, { FunctionComponent, useEffect } from 'react'
import { RenderDTO } from '../../types/campaign'
import { ApiService } from '../../service/campaign'
import { hasFlag } from 'country-flag-icons'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { Button, Card, Flex, Modal } from 'antd'
import { EyeOutlined } from '@ant-design/icons'

interface BriefRendersProps {
    campaignId: string
    briefId: string
    buildId: string
}

interface BriefRendersData {
    loading: boolean
    error: boolean
    data: RenderDTO[]
}

const BriefRenders: FunctionComponent<BriefRendersProps> = (props: BriefRendersProps) => {
    const [data, setData] = React.useState<BriefRendersData>({ loading: true, error: false, data: [] })
    const [open, setOpen] = React.useState(false)
    const [preview, setPreview] = React.useState('')

    useEffect(() => {
        const API = new ApiService()
        API.getBuildRenders(props.buildId)
            .then((res) => {
                setData({ loading: false, error: false, data: res })
            })
            .catch((err) => {
                console.log(err)
                setData({ loading: false, error: true, data: [] })
            })
    }, [props.briefId, props.buildId, props.campaignId])

    return (
        <>
            {!data.error && !data.loading && data.data && (
                <>
                    <b>Rendered variants ({data.data.length}):</b>
                    <br />
                    <br />
                    <Flex gap="middle" vertical={false}>
                        {data.data.map((rd) => {
                            const cc = rd.lang.substring(0, 2).toUpperCase()

                            let flag = getUnicodeFlagIcon('ZZ')
                            if (hasFlag(cc)) {
                                flag = getUnicodeFlagIcon(cc)
                            }
                            if (cc == 'EN') {
                                flag = getUnicodeFlagIcon('GB')
                            }

                            const subjectLine = () => {
                                const subject = rd.html.match(/<title>\n?[\t\s]*([^\n]+)\n?[\t\s]*<\/title>/)
                                const isDefined = subject && subject[1] ? subject[1] : 'undefined'
                                if (subject && subject[1]) {
                                    return (
                                        <>
                                            <b>Subject:</b>
                                            <br />
                                            <span style={{ color: subject && subject[1] ? 'green' : 'red' }}>{isDefined}</span>
                                        </>
                                    )
                                } else {
                                    return (
                                        <>
                                            <b>Build Error!</b>
                                        </>
                                    )
                                }
                            }

                            return (
                                <Card title={`${flag} - ${rd.lang}`} bordered={false} style={{ width: 250 }} key={rd.id}>
                                    {subjectLine()}
                                    <br />
                                    <br />
                                    <Button
                                        key={rd.id}
                                        onClick={() => {
                                            setPreview(rd.html)
                                            setOpen(true)
                                        }}
                                        icon={<EyeOutlined />}
                                        type="primary"
                                    >
                                        Preview
                                    </Button>
                                </Card>
                            )
                        })}
                    </Flex>
                </>
            )}

            <Modal title="Preview" centered open={open} onOk={() => setOpen(false)} onCancel={() => setOpen(false)} width={650}>
                <div dangerouslySetInnerHTML={{ __html: preview }} />
            </Modal>
        </>
    )
}

export default BriefRenders
