import React, { FunctionComponent } from 'react'

import { Tag } from 'primereact/tag'

type Props = {
    brand: string
}

const BrandTag: FunctionComponent<Props> = ({ brand }: Props) => {
    const variant = brand.toLowerCase() == 'diesel' ? 'warning':'info'

    return (
        <Tag value={brand} severity={variant}/>
    )
}

export default BrandTag
