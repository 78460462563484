import React from 'react'
import { PrimeReactProvider } from 'primereact/api'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import HomePage from './pages/HomePage'
import NotFoundPage from './pages/NotFoundPage'
import { ROUTES } from './resources/routes-constants'
import CampaignDetailPage from './pages/CampaignDetails'
import { App, Layout } from 'antd'
import { Content, Footer } from 'antd/es/layout/layout'
import BlockGalleryPage from './pages/BlockGallery'
import BriefDetailPage from './pages/BriefDetailPage'
import CampaignsPage from './pages/CampaignsPage'
import RenderViewer from './pages/RenderViewer'

import 'primereact/resources/themes/viva-dark/theme.css'
import 'primeicons/primeicons.css';

import './styles/main.sass'
import HeaderMenu from './layout/Header'
import BreadcrumbRenderer from './layout/Breadcrumb'
import BrandsPage from './pages/brands/Index'

const RootComponent: React.FC = () => {
    return (
        <PrimeReactProvider>
            {/**Antd app */}
            <App>
                <Router>
                    <HeaderMenu />
                    <Layout style={{marginTop: '5px'/* , minHeight: '95vh' */}}>
                        {/*<SidebarMenu /> */}
                        <Layout /* style={{backgroundColor: 'black'}} */>
                            <Content style={{ margin: '0 16px' }}>
                                <BreadcrumbRenderer />
                                <Routes>
                                    <Route path="*" element={<NotFoundPage />} />
                                    <Route path={ROUTES.HOMEPAGE_ROUTE} element={<HomePage />} />
                                    <Route path={ROUTES.BLOCKSPAGE_ROUTE} element={<BlockGalleryPage />} />
                                    <Route path={`${ROUTES.CAMPAIGNS._}`} element={<CampaignsPage />} />
                                    <Route path={`${ROUTES.CAMPAIGNS.BY_ID}`} element={<CampaignDetailPage />} />
                                    <Route path={`${ROUTES.CAMPAIGNS.BY_ID}/renders/:buildid`} element={<RenderViewer />} />
                                    <Route path={`${ROUTES.CAMPAIGNS.BY_ID}/brief/:briefid`} element={<BriefDetailPage />} />
                                    <Route path={`/brands`} element={<BrandsPage />} />
                                </Routes>
                            </Content>
                            <Footer style={{ textAlign: 'center' }}>Campaignly ©{new Date().getFullYear()} - Made with ❤️ in ARSENALIA</Footer>
                        </Layout>
                    </Layout>
                </Router>
            </App>
        </PrimeReactProvider>
    )
}

export default RootComponent
