import { BreadCrumb } from "primereact/breadcrumb"
import { MenuItem } from "primereact/menuitem"
import React, { FunctionComponent } from "react"
import { Link, useLocation } from "react-router-dom"
import { JSX } from "react/jsx-runtime"

const BreadcrumbRenderer: FunctionComponent = () => {
    const location = useLocation()

    const [current, setCurrent] = React.useState(location.pathname)

    React.useLayoutEffect(() => {
        if (location) {
            console.log(location.pathname)
            setCurrent(location.pathname)
        }
    }, [location])

    console.log(current)

    const home = { icon: 'pi pi-home', url: '/' }

    const breadcrumbItems: MenuItem[] | { label: string; template: () => JSX.Element }[] | undefined = [
    ]

    current
        .split('/')
        .filter((s) => s.trim() != '')
        .map((p) => {
            const pathName = p.replace('/', '').toString()
            const sanitizedLabel = pathName.charAt(0).toUpperCase() + pathName.substring(1, pathName.length)
            const currentUrl = current.lastIndexOf(p)

            const trimmed = current.substring(0, currentUrl + p.length)

            console.log(`CurrentUrl is positioned at ${currentUrl} in string ${current}`)

            console.log(sanitizedLabel)
            console.log(p)

            breadcrumbItems.push(
                { 
                    label: sanitizedLabel, 
                    template: () => <Link to={trimmed}><a className="text-primary font-semibold">{sanitizedLabel}</a></Link> 
                }
            )
        })

    //console.log(breadcrumbItems)
    return <BreadCrumb home={home} model={breadcrumbItems}/>
    //return <Breadcrumb style={{ margin: '16px 0' }} items={breadcrumbItems} />
}

export default BreadcrumbRenderer