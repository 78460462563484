import React, { FunctionComponent } from 'react'
import Container from '../../../layout/Container'
import { Button } from 'primereact/button'
import { LayoutOutlined } from '@ant-design/icons'
import { CampaignDTO } from '../../../types/campaign'
import { EditCampaignButton } from '../CreateCampaignButton'

import styles from './CampaignToolbar.module.scss'
import BrandTag from '../../../layout/BrandTag'

interface CampaignToolbarProps {
    campaign: CampaignDTO
    onEdit: (campaignDto: CampaignDTO) => void
}

const CampaignToolbar: FunctionComponent<CampaignToolbarProps> = ({ campaign, onEdit }) => {
    return (
        <div style={{margin: '10px auto'}}>
        <Container variant='horizontal'>
            <div className={styles.Title}><h2>{campaign.name}&nbsp;<BrandTag brand={campaign.brand}/></h2></div>
            <div className={styles.Spacer}>&nbsp;</div>
            <div className={styles.ButtonBar}>
                <EditCampaignButton campaign={campaign} onCampaignEdited={(cDto) => onEdit(cDto)} />
                <Button icon={<LayoutOutlined />} disabled>
                    Clone Campaign
                </Button>
            </div>
        </Container>
        </div>
    )
}

export default CampaignToolbar
